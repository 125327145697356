import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import './css/bootstrap.min.css';
import './font-awesome/css/font-awesome.min.css';
import { Provider } from 'react-redux';
import store from './store';
import intl from 'react-intl-universal';
import './css/bootstrap.min.css';
import './css/style.css';
import 'antd'; // 添加一个空白的导入语句
import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import zhCN from 'antd/lib/locale/zh_CN';

const locales = {
	"en": require('./components/intl/en-US.json'),
	"zh-cn": require('./components/intl/zh-CN.json'),
	"de": require('./components/intl/de-DE.json'),
};

if (localStorage.getItem('locale')) {
	intl.init({
		currentLocale: localStorage.getItem('locale'),
		locales: locales
	});
} else {
	intl.init({
		currentLocale: 'zh-cn',
		locales: locales
	});
	localStorage.setItem('locale', 'zh-cn');
}

React.Component.prototype.$intl = intl;

ReactDOM.render(
	<Suspense fallback={<span> </span>}>
		<Provider store={store}>
			<ConfigProvider locale={zhCN}
				theme={{
					token: {
						borderRadius: 0,
					},
				}}
			>
				{/* <StyleProvider hashPriority="high"> */}
					<App />
				{/* </StyleProvider> */}
			</ConfigProvider>
		</Provider>
	</Suspense>,
	document.getElementById('root')
);
